<template>
  <div class="title-content flex flex-col-center flex-row-between">
    <span class="title-text" :style="{'--distanceLeft': distanceLeft}">{{ title }}</span>
    <div class="line-title" v-if="isShowLine"></div>
  </div>
</template>

<script>
export default{
  name: 'TitleDOM',
  props: {
    title: {
      type: String,
      default: '基本信息'
    },
    /**
     * 条形距离左边xx px
     */
    distanceLeft: {
      type: String,
      default: '-30px'
    },
    isShowLine: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return{

    }
  },
  mounted() {

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
  .title-content{
    .title-text{position: relative;font-size: 18px;color: #242D33;}
    .title-text::before{position: absolute;content: '';left: var(--distanceLeft);top: 5px;background: #3984F5;width: 4px;height: 20px;}
    .line-title{flex: 1;height: 1px;background: #EDF1F4;margin-left: 10px;}
  }
  //.table-content{margin-top: 24px;
  //  .tab-title-text{width: 120px;height: 44px;background: #F6F7F9;border: 1px solid #E1E8EC;text-align: center;line-height: 44px;font-size: 12px;color: #444F57;box-sizing: border-box;}
  //  .con-text{width: 412px;height: 44px;padding: 0 20px;border: 1px solid #E1E8EC;line-height: 44px;font-size: 12px;color: #242D33;box-sizing: border-box;}
  //}

</style>