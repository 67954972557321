<template>
  <div class="editList">
    <div style="background: #fff;padding: 30px;" class="form-w-320">
      <el-form ref="form" :model="form" :rules="rules" label-width="115px">
        <titleDom :title="title"/>
        <div class="itemBox">
          <el-form-item label="角色名称" prop="role_name">
            <el-input v-model="form.role_name" placeholder="请填写角色名称" style="width: 320px"></el-input>
          </el-form-item>
          <el-form-item label="PC端菜单权限" prop="rule_id">
            <el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event, 'menu')">全选</el-checkbox>
            <el-checkbox v-model="menuExpand" @change="handleCheckedTreeExpand($event, 'menu')">展开/折叠</el-checkbox>
            <!--            <el-checkbox v-model="form.menuCheckStrictly" @change="handleCheckedTreeConnect($event, 'menu')">父子联动</el-checkbox>-->
            <el-scrollbar class="quest-scroll" style="width: 320px;height: 378px;">
              <el-tree
                  class="tree-border"
                  :data="menuOptions"
                  show-checkbox
                  ref="menu"
                  node-key="id"
                  :check-strictly="false"
                  empty-text="加载中，请稍候"
                  :props="defaultProps"
                  style="width: 320px"
              ></el-tree>
            </el-scrollbar>
          </el-form-item>
          <el-form-item label="pad端菜单权限" prop="rule_id_pad">
            <el-checkbox v-model="menuNodeAllPad" @change="handleCheckedTreeNodeAllPad($event, 'menuPad')">全选
            </el-checkbox>
            <el-checkbox v-model="menuExpandPad" @change="handleCheckedTreeExpandPad($event, 'menuPad')">展开/折叠
            </el-checkbox>
            <!--            <el-checkbox v-model="form.menuCheckStrictly" @change="handleCheckedTreeConnect($event, 'menu')">父子联动</el-checkbox>-->
            <el-scrollbar class="quest-scroll" style="width: 320px;height: 249px;">
              <el-tree
                  class="tree-border"
                  :data="menuOptionsPad"
                  show-checkbox
                  ref="menuPad"
                  node-key="id"
                  :check-strictly="false"
                  empty-text="加载中，请稍候"
                  :props="defaultProps"
                  style="width: 320px"
              ></el-tree>
            </el-scrollbar>

          </el-form-item>
          <el-form-item label="备注" prop="notes">
            <el-input v-model="form.notes" type="textarea" placeholder="请输入内容" style="width: 320px"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="form.status" @change="changeStatus">
              <el-radio v-for="item in dictData.status" :label="item.id" :key="item.id">{{ item.value }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <el-form-item>
          <div style="background: #fff;" class="btn_box">
            <a class="blue" href="#" @click="submit">确定</a>
            <router-link to="/roles">返回</router-link>
          </div>
        </el-form-item>
      </el-form>


    </div>


  </div>
</template>

<script>

import titleDom from "@/components/common/titleDom.vue";

const defaultForm = {
  id: null,
  rule_id: [],
  role_name: '',
  notes: '',
  status: '',
  menuCheckStrictly: true,
}
export default {
  name: "editList",
  components: {titleDom},
  data() {
    return {
      queryID: null,
      // 选项字典data
      dictData: {
        // 1=启用 2=禁用
        status: [
          {
            id: 1,
            value: '启用'
          },
          {
            id: 2,
            value: '禁用'
          }
        ]
      },
      form: {
        ...defaultForm,
      },
      rules: {
        role_name: [
          {required: true, message: '请输入角色名称', trigger: 'blur'},
        ],
        rule_id: [
          // {required: true, message: '请选择权限', trigger: 'blur'},
        ],
        rule_id_pad: [
          // {required: true, message: '请选择权限', trigger: 'blur'},
        ],
        status: [
          {required: true, message: '请选择状态', trigger: 'blur'},
        ]
      },
      // 菜单列表
      menuOptions: [],
      menuExpand: false,
      menuNodeAll: false,
      menuOptionsPad: [],
      menuExpandPad: false,
      menuNodeAllPad: false,
      defaultProps: {
        children: "children",
        label: "title"
      },
    }
  },
  computed: {
    title: function () {
      let id = this.$route.query?.id;
      return id || id === 0 ? '编辑角色' : '添加角色';
    }
  },
  mounted() {
    this.queryID = this.$route.query.id;
    if (this.queryID) {
      this.getRole(this.queryID);
    } else {
      this.getMenuTreeselect();
    }
  },
  methods: {
    getRole(id) {
      this.$api.getRole(id).then(res => {
        this.form = res.data;
        let checkedKeys = res.data.rule_id
        let checkedKeysPad = res.data.ipad_rule_id
        this.$nextTick(() => {
          this.$api.getRoleTreeList(1).then(r => {
            this.menuOptions = r.data
            checkedKeys.forEach((v) => {
              this.$nextTick(() => {
                this.$refs.menu.setChecked(v, true, false);
              })
            })
          })

          this.$api.getRoleTreeList(2).then(r => {
            this.menuOptionsPad = r.data
            checkedKeysPad.forEach((v) => {
              this.$nextTick(() => {
                this.$refs.menuPad.setChecked(v, true, false);
              })
            })
          })
        })
      })
    },
    changeStatus(val) {
      console.log(val, 111)
    },
    // 树权限（展开/折叠）
    handleCheckedTreeExpand(value, type) {
      let treeList = this.menuOptions;
      for (let i = 0; i < treeList.length; i++) {
        this.$refs.menu.store.nodesMap[treeList[i].id].expanded = value;
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAll(value, type) {
      this.$refs.menu.setCheckedNodes(value ? this.menuOptions : []);
    },
    handleCheckedTreeExpandPad(value, type) {
      let treeList = this.menuOptionsPad;
      for (let i = 0; i < treeList.length; i++) {
        this.$refs.menuPad.store.nodesMap[treeList[i].id].expanded = value;
      }
    },
    // 树权限（全选/全不选）
    handleCheckedTreeNodeAllPad(value, type) {
      this.$refs.menuPad.setCheckedNodes(value ? this.menuOptionsPad : []);

    },
    // 树权限（父子联动）
    handleCheckedTreeConnect(value, type) {
      this.form.menuCheckStrictly = value ? true : false;

    },
    /** 查询菜单树结构 */
    getMenuTreeselect() {
      this.$api.getRoleTreeList(1).then(res => {
        this.menuOptions = res.data
      })

      this.$api.getRoleTreeList(2).then(res => {
        this.menuOptionsPad = res.data
      })
    },
    // 所有菜单节点数据
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },// 所有菜单节点数据
    getMenuAllCheckedKeysPad() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menuPad.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menuPad.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    },
    // 表单重置
    reset() {
      if (this.$refs.menu != undefined) {
        this.$refs.menu.setCheckedKeys([]);
      }
      if (this.$refs.menuPad != undefined) {
        this.$refs.menuPad.setCheckedKeys([]);
      }
      this.menuExpand = false;
      this.menuNodeAll = false;
      this.menuExpandPad = false;
      this.menuNodeAllPad = false;

      this.form = {
        ...defaultForm,
        id: this.queryID
      };
      this.resetForm("form");
    },
    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          // this.form.rule_id = this.getMenuAllCheckedKeys();
          let rule_ids_pc = this.getMenuAllCheckedKeys();
          let rule_ids_pad = this.getMenuAllCheckedKeysPad();

          this.form.rule_id = rule_ids_pc;
          this.form.ipad_rule_id = rule_ids_pad;
          this.$api.editRole(this.form).then(() => {
            this.$message({
              message: this.form.id ? "修改成功" : "新增成功",
              type: 'success'
            });
            this.reset();
            this.$router.push({path: '/roles'});
          });
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style scoped lang="scss">
.editList {
  margin: 18px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  width: calc(100% - 36px);
  height: calc(100% - 36px);
  overflow: scroll;
  scrollbar-width: none;
  background: #fff;

  .resetPwd {
    width: 48px;
    height: 11px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #3984F5;
    line-height: 36px;
    position: relative;
    left: 12px;
  }

  .itemBox {
    margin-top: 28px;
  }

  .column2 {

    > div {
      height: 41px;
      float: left;
      margin-bottom: 8px;
    }

    ::v-deep .el-form-item {
      width: 650px;
    }

  }

  .btn_box {
    margin-top: 18px;

    a {
      display: inline-block;
      width: 100px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #EEF1F3;
      border-radius: 4px;
      margin-right: 10px;
      font-weight: 400;
      font-size: 14px;
      color: #6C7880;
    }

    .blue {
      background: #3984F5;
      color: #fff;
    }
  }

  .tree-box {
    border-radius: 4px;
    border: 1px solid #CEDAE0;
    max-height: 378px;
    overflow-y: auto;
    overflow-x: hidden;

    ::v-deep .el-tree {
      width: 100%;

      .el-tree-node__content {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 3px; /* 对于垂直滚动条，设置宽度 */
    height: 3px; /* 对于水平滚动条，设置高度 */
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background: #FFFFFF; /* 背景颜色 */
  }

  /* 滚动条的滑块 */
  ::-webkit-scrollbar-thumb {
    background: #A7B2B8; /* 滑块颜色 */
  }

  /* 滑块hover效果 */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* 滑块hover颜色 */
  }

  ::v-deep .quest-scroll {
    border-radius: 4px;
    border: 1px solid #CEDAE0;

    .el-tree {
      width: 100%;

      .el-tree-node__content {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

    .el-scrollbar__wrap {
      overflow-x: hidden;
      align-items: start;
    }
    .el-scrollbar__bar.is-vertical {
      width: 4px;
    }
    .el-scrollbar__thumb {
      background: #A7B2B8;
    }
  }

}
</style>